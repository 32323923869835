import { marketPlace } from '@/axios';

export default {
  addEmployee (payload) {
    return marketPlace().post('merchant/employees', payload);
  },
  getRoles () {
    return marketPlace().get('roles/employee');
  },
  getEmployees () {
    return marketPlace().get('merchant/employees');
  },
  getEmployee (id) {
    return marketPlace().get(`merchant/employees/${ id }`);
  },
  editEmployee (id, payload) {
    return marketPlace().patch(`merchant/employees/${ id }`, payload);
  }
};
