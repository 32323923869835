<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'addMerchant' })"
    >
      إضافة موظف
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-file-alt"></i> معلومات الموظف
              </h5>
            </template>
            <b-row>
              <b-col md="6" class="mb-3">
                <input-form
                  placeholder="أدخل الاسم"
                  label="الإسم"
                  v-model="employee.name"
                  name="name"
                  validate="required"
                  :disabled="!isEdit"
                ></input-form>
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form
                  placeholder="البريد الالكترونى"
                  label="البريد الالكترونى"
                  v-model="employee.email"
                  type="email"
                  name="email"
                  validate="required"
                  :disabled="!isEdit"
                ></input-form>
              </b-col>
              <b-col md="6" class="mb-3">
                <country-code
                  :validate="'required|numeric|min:9|max:11'"
                  :placeholder="$t('auth.phoneNumber')"
                  :name="$t('auth.phoneNumber')"
                  :label="$t('auth.phoneNumber')"
                  v-model="employee.phone"
                  id="phone-code-primary_phone_number"
                  @onSelect="onSelect"
                  :disabled="!isEdit"
                />
              </b-col>
              <b-col md="6">
                <div class="panel-default">
                  <main-select
                    :label-title="$t('roles.roles')"
                    :placeholder="$t('roles.roles')"
                    multiple
                    :options="roles"
                    dir="rtl"
                    v-model="employee.roles"
                    label="name"
                    :selectable="(option) => !selectedRole.includes(option.id)"
                    :reduce="(product) => product.id"
                    @deselected="removeProduct"
                    @selecting="addProduct"
                    :disabled="!isEdit"
                    validate="required"
                  />
                </div>
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form
                  :placeholder="$t('main.nationalId')"
                  :label="$t('main.nationalId')"
                  v-model="employee.national_id"
                  name="address"
                  validate="required|integer"
                  :disabled="!isEdit"
                ></input-form>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="form-actions">
          <b-button variant="outline-primary" @click="$router.back()">
            {{ $t("main.back") }}
          </b-button>
          <b-button
            v-if="isEdit"
            variant="primary"
            type="submit"
            :disabled="loadingSubmit"
          >
            <span v-if="!loadingSubmit">
              {{ $t("main.save") }}
            </span>
            <spinner-loading v-else text="loading"></spinner-loading>
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import employeeServices from "@/modules/employees/services/employees";
import roleService from "@/modules/roles/services/roles";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      employee: {
        name: "",
        email: "",
        address: "",
        image: "",
        phone: "",
        status: "active",
        country_code: "966",
        roles: [],
        national_id: "",
      },
      roles: [],
      progressLogo: 0,
      loadingSubmit: false,
      allProducts: [],
      selectedRole: [],
    };
  },
  computed: {
    isEdit() {
      return (
        this.$route.name === "editEmployee" ||
        this.$route.name === "addEmployee"
      );
    },
  },
  methods: {
    getRoles() {
      roleService.getRoles().then((res) => {
        this.roles = res.data.data;
      });
    },
    addProduct(product) {
      this.employee.roles.push(product.id);
    },
    removeProduct(product) {
      this.employee.roles = this.employee.roles.filter(
        (item) => item !== product.id
      );
    },
    getEmployeeInfo() {
      employeeServices.getEmployee(this.$route.params.id).then((res) => {
        this.employee = res.data.data;
      });
    },
    editEmployee() {
      this.loadingSubmit = true;
      employeeServices
        .editEmployee(this.$route.params.id, this.employee)
        .then(() => {
          core.showSnackbar("success", "تم تعديل الموظف بنجاح");
          this.$router.push({ name: "employees" });
        })
        .finally(() => {
          this.loadingSubmit = false;
        });
    },
    onSelect(data) {
      this.employee.country_code = data;
    },

    onSubmit() {
      if (this.$route.params.id) {
        this.editEmployee();
      } else {
        employeeServices.addEmployee(this.employee).then((res) => {
          core.showSnackbar(res.data.message);
          this.$router.push({ name: "employees" });
        });
      }
    },
  },
  created() {
    this.getRoles();
    if (this.$route.params.id) {
      this.getEmployeeInfo();
    }
  },
};
</script>
<style lang="scss">
.section_permission {
  padding: 15px;
  border: 1px dashed #ccc;
}
.panel-default {
  border-radius: 4px;
  border-color: #ddd;
  border: 1px solid transparent;
}
.panel-heading {
  border-color: #ddd;
  color: #333;
  border-bottom: 1px solid #ddd;
}
.panel-body {
  margin-top: 10px;
}
.permission_box {
  padding: 10px;
  border: 1px dashed #ccc;
  margin-bottom: 13px;
}

.input-with-icon-container {
  background-color: #fff;
  height: 45px;
  border: 1px solid #d7dbda;
  .input-with-icon {
    height: 100%;
    background: transparent !important;
    flex: 1;
    padding-inline-start: 20px !important;
    &.border-0 {
      border: none !important;
    }
  }
  .icon {
    min-width: 40px;
    height: 100%;
    font-size: 23px;
  }
}
</style>
