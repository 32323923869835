import { marketPlace } from '@/axios'

export default {
  addRole (payload) {
    return marketPlace().post('merchant/roles', payload)
  },
  getRole (id) {
    return marketPlace().get(`merchant/roles/${id}`)
  },
  editRole (id, payload) {
    return marketPlace().patch(`merchant/roles/${id}`, payload)
  },
  getAllPermissions () {
    return marketPlace().get('merchant/permissions')
  },
  getRoles () {
    return marketPlace().get('merchant/roles')
  }
}
